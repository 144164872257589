import {
  Component,
  HostBinding,
  HostListener,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivationStart, NavigationEnd, Router } from '@angular/router';
import { NotificationTypes } from '@app/shared/enums';
import {
  AccountActions,
  AppStoreState,
  GrantActions,
  GrantSelectors,
  MenuActions,
  NotificationActions,
  NotificationSelectors,
  ReportingPeriodActions,
  ResourceAccessActions,
  UserAppActions,
} from '@app/store';
import { INotification, UserGrantSelection } from '@core/models';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { environment as env } from 'environments/environment';
import { StatusCodes } from 'http-status-codes';
import { Observable, Subject } from 'rxjs';
import { delay, filter, map, takeUntil, tap } from 'rxjs/operators';
import { TsAppVersion, versions } from '../_versions';
import { getReportingPeriodState } from './store/reporting-period/actions';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnDestroy, OnInit {
  @HostBinding('attr.app-version') appVersion = '';
  @HostBinding('attr.app-version-date') appVersionDate = '';

  public appLoading = false;
  public appLoadingText = 'Loading...';
  public notifications$: Observable<INotification[]>;

  private destroyed$ = new Subject<boolean>();
  private selectedGrant$: Observable<UserGrantSelection | null>;

  constructor(
    private actions$: Actions,
    private router: Router,
    private store$: Store<AppStoreState.State>,
    private title: Title,
  ) {
    this.notifications$ = this.store$.select(
      NotificationSelectors.selectNotifications,
    );

    this.selectedGrant$ = this.store$.select(
      GrantSelectors.selectSelectedGrant,
    );
    this.actions$
      .pipe(
        ofType(
          GrantActions.getGrantListByUserFailure,
          GrantActions.setSelectedGrantFailure,
        ),
        takeUntil(this.destroyed$),
        tap(({ error, message }) => {
          // TODO: Move this when the auth api endpoints are in place
          if (error.status === StatusCodes.FORBIDDEN) {
            this.router.navigateByUrl('/auth/change-password');
          }
          this.store$.dispatch(
            NotificationActions.add({
              notificationType: NotificationTypes.DANGER,
              notificationText: message,
            }),
          );
        }),
      )
      .subscribe();
  }

  public get versions(): TsAppVersion {
    return versions;
  }

  @HostListener('window:beforeunload')
  onBeforeUnload(): void {
    this.store$.dispatch(UserAppActions.clearSecurityNoticeShown());
  }

  ngOnInit(): void {
    this.appVersion = versions.versionLong;
    this.appVersionDate = versions.versionDate;

    this.selectedGrant$
      .pipe(
        filter((selectedGrant) => !!selectedGrant),
        takeUntil(this.destroyed$),
        tap((selectedGrant) => {
          const payload = {
            grantId: !selectedGrant.grantId
              ? ''
              : selectedGrant.grantId.toString(),
            subrecipientId: !selectedGrant.subrecipientId
              ? ''
              : selectedGrant.subrecipientId.toString(),
          };

          this.store$.dispatch(
            ResourceAccessActions.getResourceAccess({ payload }),
          );
          this.store$.dispatch(GrantActions.getGrantDetails({ payload }));
          this.store$.dispatch(AccountActions.getAccountRequest());
          this.store$.dispatch(
            ReportingPeriodActions.getReportingPeriodState(),
          );
        }),
      )
      .subscribe();

    // Set the title based on the route
    this.router.events
      .pipe(
        filter((event) => event instanceof ActivationStart),
        // eslint-disable-next-line @typescript-eslint/dot-notation
        map((event) => event['snapshot'].data),
        filter((data) => !!data),
      )
      .subscribe((data) => {
        this.title.setTitle(
          data.title ? data.title + ' | ' + env.appTitle : env.appTitle,
        );

        if (data.appMenu) {
          this.store$.dispatch(
            MenuActions.setActiveMenu({ payload: data.appMenu }),
          );
        }

        if (data.adminMenu) {
          this.store$.dispatch(
            MenuActions.setActiveAdminMenu({ payload: data.adminMenu }),
          );
        }
      });

    // Scroll to top (main app)
    this.router.events
      .pipe(
        delay(100),
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this.destroyed$),
        tap(() => document.querySelector('main')?.scrollTo(0, 0)),
      )
      .subscribe();

    this.store$.dispatch(GrantActions.getSelectedGrant());
    this.store$.dispatch(UserAppActions.getUserAppState());
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
