import { CommonModule } from '@angular/common';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@shared/shared.module';
import {
  AppMenuComponent,
  AppNavComponent,
  DialogComponent,
  MainComponent,
  SecurityPrivacyComponent,
  ToastComponent,
} from './ui';

const COMPONENTS = [
  AppMenuComponent,
  AppNavComponent,
  DialogComponent,
  MainComponent,
  SecurityPrivacyComponent,
  ToastComponent,
];

const MODULES = [CommonModule, RouterModule, SharedModule];

@NgModule({
  declarations: COMPONENTS,
  imports: MODULES,
  exports: [...COMPONENTS],
  providers: [],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {}
}
