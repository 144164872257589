<app-gov-site-link></app-gov-site-link>
<app-security-link></app-security-link>
<div class="flex-column maintenance-container">
  <div
    class="maintenance mat-elevation-z2 animate__animated animate__faster animate__fadeIn"
  >
    <div class="logo-row">
      <div class="logo-container">
        <img src="assets/oash-logo.svg" alt="OPA" />
      </div>
      <div class="logo-container">
        <img
          src="/assets/logo-fpar.png"
          alt="Title X | Family Planning Annual Report"
        />
      </div>
    </div>
    <div class="message-row">
      <h1 class="title">Maintenance</h1>
      <p class="message">
        The Family Planning Annual Report (FPAR) is currently undergoing
        required maintenance. Please check back later.
      </p>
    </div>
  </div>
</div>
<app-auth-footer></app-auth-footer>
