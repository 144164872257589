import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  AdminGuard,
  AuthGuard,
  GrantSelectionGuard,
  SupportStaffGuard,
} from '@core/helpers';
import { MainComponent, SecurityPrivacyComponent } from '@core/ui';
import { MaintenancePageComponent } from './maintenance-page/maintenance-page.component';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'maintenance-page',
    component: MaintenancePageComponent,
    data: { title: 'Maintenance Page' },
  },
  { path: '', redirectTo: 'myfpar', pathMatch: 'full' },
  {
    path: '',
    component: MainComponent,
    canActivate: [AuthGuard, GrantSelectionGuard],
    canActivateChild: [SupportStaffGuard],
    children: [
      {
        path: 'account',
        loadChildren: () =>
          import('./account/account.module').then((m) => m.AccountModule),
        data: { allowSupportStaff: true },
      },
      {
        path: 'clinic-locator',
        loadChildren: () =>
          import('./clinic-locator/clinic-locator.module').then(
            (m) => m.ClinicLocatorModule,
          ),
      },
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
      },
      {
        path: 'myfpar',
        loadChildren: () =>
          import('./myfpar/myfpar.module').then((m) => m.MyFparModule),
      },
      {
        path: 'help',
        loadChildren: () =>
          import('./help/help.module').then((m) => m.HelpModule),
      },
      {
        path: 'grant',
        loadChildren: () =>
          import('./grant-portal/portal.module').then(
            (m) => m.GrantPortalModule,
          ),
      },
      {
        path: 'my-grants',
        loadChildren: () =>
          import('./my-grants/my-grants.module').then((m) => m.MyGrantsModule),
      },
      {
        path: 'profile-cover-sheet',
        loadChildren: () =>
          import('./profile-cover-sheet/profile-cover-sheet.module').then(
            (m) => m.GranteeProfileCoverSheetModule,
          ),
      },
      {
        path: 'review',
        loadChildren: () =>
          import('./review-portal/review-portal.module').then(
            (m) => m.ReviewPortalModule,
          ),
      },
      {
        path: 'security-privacy',
        component: SecurityPrivacyComponent,
      },
      {
        path: 'settings',
        loadChildren: () =>
          import('./settings/settings.module').then((m) => m.SettingsModule),
        canActivate: [AdminGuard],
        data: { allowSupportStaff: true },
      },
      {
        path: 'table-archive',
        loadChildren: () =>
          import('./table-archive/table-archive.module').then(
            (m) => m.TableArchiveModule,
          ),
      },
      {
        path: 'users',
        loadChildren: () =>
          import('./users/users.module').then((m) => m.UsersModule),
      },
    ],
  },
  {
    path: 'print-submission',
    loadChildren: () =>
      import('./core/ui/print-submission/print-submission.module').then(
        (m) => m.PrintSubmissionModule,
      ),
  },
  { path: '**', redirectTo: 'myfpar' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { paramsInheritanceStrategy: 'always' }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
