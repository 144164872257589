import { enableProdMode, NgModuleRef } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppInjector } from '@app/shared/utilities';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

setTimeout(() => {
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .then((moduleRef: NgModuleRef<AppModule>) =>
      AppInjector.set(moduleRef.injector),
    )
    .catch((err) => console.error(err));
}, 2000);
