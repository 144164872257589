import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  AccountModule,
  AuthenticationModule,
  ClinicStepperModule,
  DashboardModule,
  FparReportModule,
  FparTableModule,
  GrantModule,
  GrantProfileCoverSheetModule,
  MenuModule,
  NotificationModule,
  PortalReviewModule,
  PortalStepperModule,
  ReportingPeriodModule,
  ResourceAccessModule,
  SettingsModule,
  SiteModule,
  TableArchiveModule,
  UserAppModule,
  UserModule,
} from '@app/store';
import { metaReducers } from '@core/helpers/meta-reducer';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forRoot(
      {},
      {
        metaReducers,
      },
    ),
    EffectsModule.forRoot([]),
    StoreRouterConnectingModule.forRoot(),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
    AccountModule,
    AuthenticationModule,
    ClinicStepperModule,
    DashboardModule,
    FparReportModule,
    FparTableModule,
    GrantModule,
    GrantProfileCoverSheetModule,
    MenuModule,
    NotificationModule,
    PortalReviewModule,
    PortalStepperModule,
    ReportingPeriodModule,
    ResourceAccessModule,
    SettingsModule,
    SiteModule,
    TableArchiveModule,
    UserAppModule,
    UserModule,
  ],
})
export class AppStoreModule {}
